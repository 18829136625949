// @ts-nocheck
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import {
  StripeElements,
  Stripe,
  StripeCardNumberElement,
  StripeError,
  PaymentIntent,
} from '@stripe/stripe-js';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import ThemeContext from '@digg/shared/src/common/theme/themeContext';
import { Theme } from '@digg/shared/src/common/theme/theme';
import CallToActionLoader from '@digg/shared/src/common/components/callToAction/CallToActionLoader';
import { Subtitle, ButtonText } from '@digg/shared/src/common/components/text';
import { openPaymentTerms } from '@digg/shared/src/domain/payment/forces/actions';
import {
  setPaymentFormLoading,
  paymentCardFormSubmissionSuccess,
} from '@askeladden/restaurant/dist/domain/payment/actions';
import { TouchableOpacity } from 'react-native';
import config from '../config';
import { getLocalizedErrorMessage } from '../utils/i18n.utils';
import ErrorMessage from './ErrorMessage';

interface Props {
  elements: StripeElements | null;
  stripe: Stripe | null;
}

interface State {
  error: StripeError | null;
  isSubmitButtonLoading: boolean;
}

class CheckoutForm extends React.Component<Props, State> {
  static contextType = ThemeContext;

  context!: React.ContextType<typeof ThemeContext>;

  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
      isSubmitButtonLoading: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const wasStripeDependencySet = !!prevProps.stripe && !!prevProps.elements;
    const isStripeDependencySet = !!this.props.stripe && !!this.props.elements;
    if (!wasStripeDependencySet && isStripeDependencySet) {
      this.onStripeLoaded(this.props.stripe);
    }
  }

  async onStripeLoaded(stripe: Stripe) {
    this.dispatchLoadingToReactNativeWebView(false);
    try {
      const paymentIntent = await this.getPaymentIntent(stripe);
      if (paymentIntent.status === 'requires_action') {
        await this.handleThreeDSecure(stripe, cardElement);
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  dispatchLoadingToReactNativeWebView(loading: boolean) {
    this.dispatchActionToReactNativeWebView(setPaymentFormLoading(loading));
  }

  dispatchActionToReactNativeWebView(action: { type: string; payload: { [key: string]: any } }) {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    }
  }

  async getPaymentIntent(stripe: Stripe) {
    const { paymentIntent, error } = await stripe.retrievePaymentIntent(
      config.payment.stripeClientSecret
    );
    if (error != null) {
      throw error;
    }
    return paymentIntent as PaymentIntent;
  }

  async confirmCardPayment(stripe: Stripe, cardElement: StripeCardNumberElement) {
    const { error } = await stripe.confirmCardPayment(config.payment.stripeClientSecret, {
      payment_method: {
        card: cardElement,
      },
      save_payment_method: true,
    });
    if (error != null) {
      throw error;
    }
  }

  async handleThreeDSecure(stripe: Stripe) {
    const { error } = await stripe.confirmCardPayment(config.payment.stripeClientSecret);
    if (error != null) {
      throw error;
    }
  }

  async handleSubmit(
    stripe: Stripe,
    elements: StripeElements,
    event?: React.FormEvent<HTMLFormElement>
  ) {
    if (event) {
      event.preventDefault();
    }

    try {
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement == null) {
        throw new Error('Card element is not set');
      }

      this.setState({ error: null, isSubmitButtonLoading: true });
      await this.confirmCardPayment(stripe, cardElement);
      this.setState({ isSubmitButtonLoading: false });
      this.dispatchActionToReactNativeWebView(paymentCardFormSubmissionSuccess());
    } catch (error) {
      this.setState({ error, isSubmitButtonLoading: false });
    }
  }

  getOptions() {
    return {
      hidePostalCode: true,
      style: {
        base: {
          fontFamily: this.context.typography.fontFamily.regular,
          fontSize: `${this.context.typography.size.h4}px`,
          color: this.context.color.text100,
          fontSmoothing: 'antialiased',
          '::placeholder': {
            lineHeight: '32px',
          },
        },
        invalid: {
          color: this.context.color.primary,
        },
      },
    };
  }

  render() {
    const styles = themedStyles(this.context);
    const { stripe, elements } = this.props;
    const { error, isSubmitButtonLoading } = this.state;

    if (stripe == null || elements == null) {
      return null;
    }

    return (
      <form css={styles.form} onSubmit={(event) => this.handleSubmit(stripe, elements, event)}>
        <div>
          <label css={styles.label} htmlFor="cardNumber">
            Kortnummer
          </label>
          <div css={styles.inputWrapper}>
            <CardNumberElement id="cardNumber" options={this.getOptions()} />
          </div>
          <div css={styles.expiryCvcContainer}>
            <div>
              <label css={styles.label} htmlFor="expiry">
                Utløpsdato
              </label>
              <div css={[styles.inputWrapper, styles.expiryInput]}>
                <CardExpiryElement id="expiry" options={this.getOptions()} />
              </div>
            </div>
            <div>
              <label css={styles.label} htmlFor="cvc">
                CVC
              </label>
              <div css={[styles.inputWrapper, styles.cvcInput]}>
                <CardCvcElement id="cvc" options={this.getOptions()} />
              </div>
            </div>
          </div>

          {error && <ErrorMessage message={getLocalizedErrorMessage(error)} />}
        </div>
        <CallToActionLoader
          isLoading={isSubmitButtonLoading}
          disabled={isSubmitButtonLoading}
          type="success"
          title="Legg til kort og bestill"
          onPress={() => this.handleSubmit(stripe, elements)}
        />
        <TouchableOpacity
          onPress={() => this.dispatchActionToReactNativeWebView(openPaymentTerms())}>
          <Subtitle css={styles.termsButton}>
            <ButtonText>Se våre brukervilkår for betaling</ButtonText>
          </Subtitle>
        </TouchableOpacity>
      </form>
    );
  }
}

const themedStyles = (theme: Theme) => ({
  form: css`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  inputWrapper: css`
    border: 1px ${theme.color.backgroundContrast100} solid;
    border-radius: 20px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: 'center';
    padding-top: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
  `,
  expiryCvcContainer: css`
    display: flex;
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 2rem;
  `,
  expiryInput: css`
    min-width: 150px;
  `,
  cvcInput: css`
    min-width: 100px;
    margin-left: 0.5rem;
  `,
  label: css`
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    display: block;
  `,
  termsButton: css`
    display: flex;
    justify-content: center;
  `,
});

export default CheckoutForm;
