import React from 'react';
import { LayoutAnimation, NativeModules } from 'react-native';
import Spinner from '../spinner/Spinner';
import CallToAction, { Props as CallToActionProps } from './CallToAction';

const { UIManager } = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export type Props = {
  isLoading?: boolean;
} & CallToActionProps;

class CallToActionLoader extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading !== this.props.isLoading) {
      LayoutAnimation.configureNext({
        ...LayoutAnimation.Presets.spring,
        duration: 300,
      });
    }
  }

  render() {
    const { title, isLoading, children, ...props } = this.props;

    return (
      <CallToAction title={isLoading ? null : title} {...props}>
        {isLoading && <Spinner style={{ position: 'absolute' }} />}
        {!isLoading && children}
      </CallToAction>
    );
  }
}

export default CallToActionLoader;
