// @ts-nocheck
/** @jsx jsx */
// eslint-disable-next-line unused-imports/no-unused-imports-ts
import React, { useContext } from 'react';
import { css, jsx } from '@emotion/core';
import { Theme } from '@digg/shared/src/common/theme/theme';
import ThemeContext from '@digg/shared/src/common/theme/themeContext';
import { BodyText } from '@digg/shared/src/common/components/text';

interface Props {
  message?: string;
}

const ErrorMessage = ({ message = 'Noe gikk galt. Vennligst prøv igjen' }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  return (
    <div css={styles.container}>
      <BodyText css={styles.text}>{message}</BodyText>
    </div>
  );
};

const themedStyles = (theme: Theme) => ({
  container: css`
    display: block;
    margin-bottom: 1rem;
  `,
  text: css`
    color: ${theme.color.primary};
    font-size: ${theme.typography.size.subtitle}px;
  `,
});

export default ErrorMessage;
