import { StripeError } from '@stripe/stripe-js';
import stripeErrorMessages from './stripeErrorMessages.json';

function instanceOfStripeError(error: any): error is StripeError {
  return 'code' in error;
}

export const getLocalizedErrorMessage = (error: StripeError | Error): string | undefined => {
  if (instanceOfStripeError(error) && error.code != null) {
    const localizedError = stripeErrorMessages[error.code];
    if (localizedError) {
      return localizedError;
    }
    if (error.message != null) {
      return error.message;
    }
  }
};
