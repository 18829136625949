import React, { ReactNode, useContext } from 'react';
import {
  ViewStyle,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import { H5 } from '../text';
import { assertUnreachable } from '../../../utils/ts.utils';
import ThemeContext from '../../theme/themeContext';
import themedStyles from './CallToAction.styles';

type ButtonType = 'default' | 'success' | 'external';

export type Props = {
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
  isSelected?: boolean;
  fullWidth?: boolean;
  fullWidthColumnLayout?: boolean;
  type?: ButtonType;
  title?: string | null;
  disableFontScaling?: boolean;
} & TouchableWithoutFeedbackProps;

const getStyles = ({ type = 'default', isSelected = false }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  switch (type) {
    case 'default':
      return {
        container: isSelected ? styles.containerDefaultSelected : styles.containerDefault,
        text: isSelected ? styles.textDefaultSelected : styles.textDefault,
      };
    case 'success':
      return {
        container: styles.containerSuccess,
        text: styles.textSuccess,
      };
    case 'external':
      return {
        container: styles.containerExternal,
        text: styles.textSuccess,
      };
    default:
      return assertUnreachable(type);
  }
};

const CallToAction = ({
  style,
  fullWidth,
  fullWidthColumnLayout,
  disableFontScaling,
  ...props
}: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  const { container: containerStyle, text: textStyle } = getStyles(props);
  const fullWidthStyling = {
    fullWidth: fullWidth ? styles.fullWidth : null,
    fullWidthColumnLayout: fullWidthColumnLayout ? styles.fullWidthColumnLayout : null,
  };

  return (
    <TouchableOpacity
      style={[
        styles.container,
        containerStyle,
        fullWidthStyling.fullWidth,
        fullWidthStyling.fullWidthColumnLayout,
        props.disabled ? styles.disabled : null,
        style,
      ]}
      {...props}>
      {props.title && (
        <H5 allowFontScaling={!disableFontScaling} style={textStyle}>
          {props.title}
        </H5>
      )}
      {props.children}
    </TouchableOpacity>
  );
};

export default CallToAction;
