import { registerThemes } from './themedStyles';

export interface Theme {
  screen: {
    paddingHorizontal: number;
  };
  typography: {
    fontFamily: { regular: string; bold: string; light: string };
    size: {
      h1: number;
      h2: number;
      h3: number;
      h4: number;
      h5: number;
      subtitle: number;
      body: number;
      small: number;
    };
  };
  color: {
    primary: string;
    secondary: string;
    tertiary: string;

    background100: string;
    background200: string;
    backgroundContrast100: string;
    text100: string;
    text200: string;
    textContrast100: string;
    green100: string;
    green200: string;
    green300: string;
    grey100: string;
    vippsPrimary: string;
  };
  sizes: {
    xsmall: number;
    small: number;
    smallLarge: number;
    medium: number;
    mediumLarge: number;
    largeSmall: number;
    large: number;
    xlarge: number;
    xxlarge: number;
  };
}

const baseTheme = {
  screen: { paddingHorizontal: 30 },
  typography: {
    fontFamily: { regular: 'GTPressura', bold: 'GTPressura-Bold', light: 'GTPressura-Light' },
    size: {
      h1: 46,
      h2: 30,
      h3: 22,
      h4: 20,
      h5: 18,
      subtitle: 15,
      body: 12,
      small: 10,
    },
  },
  sizes: {
    xsmall: 4,
    small: 8,
    smallLarge: 10,
    medium: 16,
    mediumLarge: 20,
    largeSmall: 30,
    large: 32,
    xlarge: 36,
    xxlarge: 40,
    xxxlarge: 60,
  },
};

const lightTheme: Theme = {
  ...baseTheme,
  color: {
    primary: '#E0730D',
    secondary: '#122E1F',
    tertiary: '#E3DBC9',

    background100: '#E3DBC9',
    background200: '#dcd4c3',
    backgroundContrast100: '#122E1F',
    text100: '#122E1F',
    text200: '#858f7e',
    textContrast100: '#E3DBC9',

    vippsPrimary: '#F35723',
    green100: '#296545',
    green200: '#0A1A12',
    green300: '#08150E',
    grey100: '#707070',
  },
};

const darkTheme: Theme = {
  ...baseTheme,
  color: {
    primary: '#E0730D',
    secondary: '#122E1F',
    tertiary: '#E3DBC9',

    background100: '#122E1F',
    background200: '#111E1F',
    backgroundContrast100: '#E3DBC9',
    text100: '#E3DBC9',
    text200: '#86907f',
    textContrast100: '#122E1F',

    vippsPrimary: '#F35723',
    green100: '#296545',
    green200: '#0A1A12',
    green300: '#08150E',
    grey100: '#707070',
  },
};

export const themes = { light: lightTheme, dark: darkTheme };
export type ThemeType = keyof typeof themes;

const createStyleSheet = registerThemes(themes, () => 'light');

export { createStyleSheet };
