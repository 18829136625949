import { StyleSheet } from 'react-native';
import { Theme } from '../../theme/theme';

export default (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: 40,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      borderWidth: 1,
      paddingHorizontal: 20,
      paddingVertical: 5,
    },
    fullWidth: {
      flex: 1,
    },
    fullWidthColumnLayout: {
      minWidth: '100%',
    },
    // default
    containerDefault: {
      borderColor: theme.color.backgroundContrast100,
    },
    containerDefaultSelected: {
      borderColor: theme.color.backgroundContrast100,
      backgroundColor: theme.color.backgroundContrast100,
    },
    textDefault: {
      color: theme.color.text100,
    },
    textDefaultSelected: {
      color: theme.color.textContrast100,
    },
    // success
    containerSuccess: {
      borderWidth: 0,
      backgroundColor: theme.color.primary,
    },
    textSuccess: {
      color: theme.color.tertiary,
    },
    containerExternal: {
      borderWidth: 0,
      backgroundColor: theme.color.vippsPrimary,
      width: '100%',
    },
    disabled: {
      opacity: 0.45,
    },
  });
