import { createAction } from 'deox';

export const deepLinkOpened = createAction(
  'PAYMENT_DEEPLINK_OPENED',
  (handler) => (url: string) => {
    return handler({ url });
  }
);

export const openPaymentTerms = createAction('PAYMENT_OPEN_TERMS');
