import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Theme } from '../../theme/theme';
import ThemeContext from '../../theme/themeContext';
import BaseText, { Props } from './BaseText';

const themedStyles = (theme: Theme) =>
  StyleSheet.create({
    text: {
      fontSize: theme.typography.size.subtitle,
      color: theme.color.text100,
      fontFamily: theme.typography.fontFamily.bold,
      textDecorationLine: 'underline',
    },
  });

const ButtonText = ({ style, ...props }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  return <BaseText style={[styles.text, style]} {...props} />;
};

export default ButtonText;
