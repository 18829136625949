import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Theme } from '../../theme/theme';
import ThemeContext from '../../theme/themeContext';
import BaseText, { Props } from './BaseText';

const themedStyles = (theme: Theme) =>
  StyleSheet.create({
    text: {
      fontSize: theme.typography.size.body,
      color: theme.color.text100,
    },
  });

const BodyText = ({ style, ...props }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  return <BaseText style={[styles.text, style]} {...props} />;
};

export const InlineBodyText = ({ style, ...props }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  return (
    <BaseText style={[styles.text, style]}>
      <BaseText {...props} />
    </BaseText>
  );
};

export default BodyText;
