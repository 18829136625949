import React, { useContext } from 'react';
import { ViewStyle, StyleProp } from 'react-native';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Global, css } from '@emotion/core';
import ThemeContext from '@digg/shared/src/common/theme/themeContext';
import config from '../config';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(config.payment.stripeClientId, { locale: 'no' });

interface Props {
  style?: StyleProp<ViewStyle>;
}

const App = (props: Props) => {
  const theme = useContext(ThemeContext);

  const elementsOptions = {
    fonts: [
      {
        family: theme.typography.fontFamily.regular,
        src:
          "url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.eot?#iefix') format('embedded-opentype'), url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff2') format('woff2'), url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff') format('woff'), url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.ttf') format('truetype'), url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.svg#GTPressura') format('svg')",
        weight: 'normal',
      },
    ],
  };

  return (
    <>
      <Global
        styles={css`
          @font-face {
            font-family: ${theme.typography.fontFamily.regular};
            src: url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.eot');
            src: url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.eot?#iefix')
                format('embedded-opentype'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff2')
                format('woff2'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff') format('woff'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.ttf')
                format('truetype'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.svg#GTPressura')
                format('svg');
          }

          @font-face {
            font-family: ${theme.typography.fontFamily.bold};
            src: url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.eot');
            src: url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.eot?#iefix')
                format('embedded-opentype'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff2')
                format('woff2'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.woff') format('woff'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.ttf')
                format('truetype'),
              url('//db.onlinewebfonts.com/t/526f12fbb246b9f88ad2e98303d08c83.svg#GTPressura')
                format('svg');
          }

          body {
            margin: 0;
            padding: env(safe-area-inset-top, 1rem) ${theme.screen.paddingHorizontal}px
              env(safe-area-inset-bottom, 1rem) ${theme.screen.paddingHorizontal}px;
            box-sizing: border-box;
            background-color: ${theme.color.background100};
            font-size: ${theme.typography.size.body}px;
            font-family: ${theme.typography.fontFamily.regular};
            color: ${theme.color.text100};
          }
        `}
      />
      <Elements stripe={stripePromise} options={elementsOptions}>
        <ElementsConsumer>
          {({ elements, stripe }) => <CheckoutForm elements={elements} stripe={stripe} />}
        </ElementsConsumer>
      </Elements>
    </>
  );
};

export default App;
