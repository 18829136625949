import { parseQueryString } from './utils/url.utils';
import { assertIfUndefined } from './utils/assert.utils';

const { client_id: clientId, client_secret: clientSecret } = parseQueryString();

assertIfUndefined({ clientId, clientSecret });

export default {
  payment: {
    // Cast values as string as it will fail at assertIfUndefined if its not set
    stripeClientId: clientId as string,
    stripeClientSecret: clientSecret as string,
  },
  sentryDSN: 'https://c308fe48edb64aa3b6dcb285e08593cd@o242872.ingest.sentry.io/5215420',
  sentryEnvironment: process.env.SENTRY_ENVIRONMENT || 'development',
};
