import React, { ReactNode } from 'react';
import { TextStyle, StyleProp, Text as RNText, TextProps } from 'react-native';
import { createStyleSheet } from '../../theme/theme';
import { useTheme } from '../../theme/themedStyles';

const themedStyles = createStyleSheet((theme) => ({
  default: {
    fontFamily: theme.typography.fontFamily.regular,
  },
}));

export type Props = {
  children: ReactNode;
  style?: StyleProp<TextStyle>;
  numberOfLines?: number;
} & TextProps;

const Text = ({ style, children, ...props }: Props) => {
  const [styles] = useTheme(themedStyles);
  return (
    <RNText {...props} style={[styles.default, style]}>
      {children}
    </RNText>
  );
};

const BaseText = ({ numberOfLines = 0, children, ...rest }: Props) => {
  return (
    <Text numberOfLines={numberOfLines} {...rest}>
      {children}
    </Text>
  );
};

export default BaseText;
