import React from 'react';
import { ViewStyle, StyleProp } from 'react-native';
import { themes } from '@digg/shared/src/common/theme/theme';
import ThemeContext from '@digg/shared/src/common/theme/themeContext';
import StripeElements from './StripeElements';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const App = (props: Props) => {
  return (
    <ThemeContext.Provider value={themes.dark}>
      <StripeElements />
    </ThemeContext.Provider>
  );
};

export default App;
