import React, { useContext } from 'react';
import { ViewStyle, StyleProp, ActivityIndicator } from 'react-native';
import ThemeContext from '../../theme/themeContext';

interface Props {
  style?: StyleProp<ViewStyle>;
  size?: 'small' | 'large';
  color?: string;
}

const Spinner = (props: Props) => {
  const theme = useContext(ThemeContext);
  const { size = 'small', color = theme.color.text100, ...rest } = props;
  return <ActivityIndicator size={size} color={color} {...rest} />;
};

export default Spinner;
