import React, { useContext } from 'react';
import { StyleSheet } from 'react-native';
import { Theme } from '../../theme/theme';
import ThemeContext from '../../theme/themeContext';
import BaseText, { Props } from './BaseText';

const themedStyles = (theme: Theme) =>
  StyleSheet.create({
    text: {
      fontSize: theme.typography.size.h5,
      color: theme.color.text100,
      marginTop: -1,
    },
  });

const H5 = ({ style, ...props }: Props) => {
  const styles = themedStyles(useContext(ThemeContext));
  return <BaseText style={[styles.text, style]} {...props} />;
};

export default H5;
